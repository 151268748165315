/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'
import GlobalStyles from '@stiloso/components/GlobalStyles.js'
import colorPrimary from './colorPrimary.js'
import colorSecondary from './colorSecondary.js'
import colorGreen from './colorGreen.js'
import colorRed from './colorRed.js'

const GlobalStyles_ = () => (
  <GlobalStyles
    colorPrimary={colorPrimary}
    colorSecondary={colorSecondary}
    colorGreen={colorGreen}
    colorRed={colorRed}
  />
)

export default GlobalStyles_
