/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import PricingSectionStiloso from '@stiloso/sections/PricingSection.js'
import { useTranslation } from '@multilocale/react/index.js'

const PricingSection = ({ locale }) => {
  const { t } = useTranslation(locale)

  return (
    <PricingSectionStiloso
      locale={locale}
      plans={[
        {
          amount: 0,
          billingFrequency: 'MONTHLY',
          currencyCode: 'USD',
          name: 'Free',
          lines: [
            t('{articles_count} articles').replace('{articles_count}', 60),
            t('{languages_count} languages').replace('{languages_count}', 3),
          ],
        },
        {
          amount: 19,
          billingFrequency: 'MONTHLY',
          currencyCode: 'USD',
          name: 'Basic',
          lines: [
            t('{articles_count} articles').replace('{articles_count}', 600),
            t('{languages_count} languages').replace('{languages_count}', 10),
          ],
        },
        {
          amount: 49,
          billingFrequency: 'MONTHLY',
          currencyCode: 'USD',
          name: 'Plus',
          lines: [
            t('{articles_count} articles').replace('{articles_count}', 1800),
            t('{languages_count} languages').replace('{languages_count}', 30),
          ],
        },
        {
          amount: 89,
          billingFrequency: 'MONTHLY',
          currencyCode: 'USD',
          name: 'Pro',
          lines: [
            t('{articles_count} articles').replace('{articles_count}', 6000),
            t('{languages_count} languages').replace('{languages_count}', '∞'),
          ],
        },
      ]}
    />
  )
}

export default PricingSection
