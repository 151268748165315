/* Copyright 2013 - 2024 Waiterio LLC */
import IS_DEVELOPMENT from './IS_DEVELOPMENT.js'
import IS_STAGING from './IS_STAGING.js'
import IS_TESTING from './IS_TESTING.js'
import IS_BROWSER from './IS_BROWSER.js'
import IS_NODE from './IS_NODE.js'

export default function getPolyblogAppUrl() {
  let url = 'https://app.polyblog.io'

  if (IS_BROWSER && window.POLYBLOG_APP_URL === '{{{POLYBLOG_APP_URL}}}') {
    delete window.POLYBLOG_APP_URL
  }

  if (IS_NODE && process.env.POLYBLOG_APP_URL === '{{{POLYBLOG_APP_URL}}}') {
    delete process.env.POLYBLOG_APP_URL
  }

  if (IS_BROWSER && window.POLYBLOG_APP_URL) {
    url = `${window.POLYBLOG_APP_URL}`
  } else if (IS_NODE && process.env.POLYBLOG_APP_URL) {
    url = process.env.POLYBLOG_APP_URL
  } else if (IS_STAGING) {
    url = 'https://app.polyblog-staging.io'
  } else if (IS_DEVELOPMENT || IS_TESTING) {
    // url = 'https://app.polyblog-staging.io';
    // url = 'http://192.168.1.64:7272';
    url = 'http://localhost:7272'
  }

  // if (IS_STAGING || IS_DEVELOPMENT) {
  //   console.debug('Polyblog.url = ' + url)
  // }

  return url
}
