/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from '@multilocale/react/index.js'
import getBrowserLanguage from '@monorepo/shared/getBrowserLanguage.js'
import ErrorBoundary from '@stiloso/components/ErrorBoundary.js'
import container from '@stiloso/styles/bootstrap/container.js'
import colSm12 from '@stiloso/styles/bootstrap/colSm12.js'
import row from '@stiloso/styles/bootstrap/row.js'
import dNone from '@stiloso/styles/bootstrap/dNone.js'
import dLgBlock from '@stiloso/styles/bootstrap/dLgBlock.js'
import dLgNone from '@stiloso/styles/bootstrap/dLgNone.js'
import FooterComponent from '../components/FooterComponent.js'
import NavigationComponent from '../components/NavigationComponent.js'

const errorSection = css`
  padding: 100px 0;
  text-align: center;
`

const goBackButton = css`
  background-color: var(--color-primary);
  color: white;
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  line-height: 48px;
  padding: 0px 16px;
  border-radius: 4px;
  cursor: pointer;
  border: 0;
  :hover {
    background-color: var(--color-primary-light);
  }
  :active {
    background-color: var(--color-primary-dark);
  }
`

export const path = '*'

export const Head = () => {
  let { locale } = useParams()
  if (!locale) {
    locale = getBrowserLanguage() || 'en'
  }
  const { t } = useTranslation(locale)

  const title = `${t('Page not found')} | Waiterio`
  return <title>{title}</title>
}

const NotFoundPage = () => {
  let { locale } = useParams()
  const { t } = useTranslation(locale)
  const navigate = useNavigate()

  if (!locale) {
    locale = getBrowserLanguage() || 'en'
  }

  return (
    <>
      <NavigationComponent locale={locale} />
      <ErrorBoundary>
        <div css={[dLgNone, { height: 64, backgroundColor: '#f5f7fb' }]} />
        <div
          css={[dNone, dLgBlock, { height: 88, backgroundColor: '#f5f7fb' }]}
        />
        <section css={errorSection}>
          <div css={container}>
            <div css={row}>
              <div css={colSm12}>
                <div
                  css={{
                    fontSize: 180,
                    fontWeight: 700,
                    lineHeight: '180px',
                    color: '#333333',
                  }}
                >
                  404
                </div>
                <div
                  css={{
                    fontSize: 24,
                    fontWeight: 600,
                    lineHeight: '24px',
                    margin: '20px 0px',
                  }}
                >
                  {t('Page not found')}
                </div>
                <button
                  type="button"
                  onClick={() => navigate(-1)}
                  css={goBackButton}
                >
                  {t('Go back to the previous page')}
                </button>
              </div>
            </div>
          </div>
        </section>
      </ErrorBoundary>
      <FooterComponent locale={locale} />
    </>
  )
}

export default NotFoundPage
