/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'

const CartIcon = () => (
  <svg
    width="100"
    height="92"
    viewBox="0 0 100 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M92.3075 0.40918H7.8713C3.84627 0.40918 0.536621 3.43771 0.536621 7.12098V63.516C0.536621 67.1993 3.84627 70.2277 7.8713 70.2277H33.8104L50.0892 91.3452L66.3684 70.2277H92.3075C96.3325 70.2277 99.6422 67.1993 99.6422 63.516V7.12098C99.6422 3.35586 96.422 0.40918 92.3075 0.40918Z"
      fill="white"
    />
    <path
      d="M50.0893 92L33.542 70.637H7.78191C3.48854 70.637 0 67.4449 0 63.5161V7.12106C0 3.19223 3.48854 0 7.78191 0H92.2181C96.5115 0 100 3.19223 100 7.12106V63.5161C100 67.4449 96.5115 70.637 92.2181 70.637H66.458L50.0893 92ZM7.87144 0.818505C4.11474 0.818505 0.983985 3.60148 0.983985 7.12106V63.5161C0.983985 66.9538 4.0253 69.8185 7.87144 69.8185H34.0787L50.0893 90.6086L66.1004 69.8185H92.3076C96.0643 69.8185 99.1951 67.0357 99.1951 63.5161V7.12106C99.1951 3.68333 96.1538 0.818505 92.3076 0.818505H7.87144Z"
      fill="#261134"
    />
    <path
      d="M71.7003 28.5567L38.1506 24.9279L36.7812 18.6972L29.7972 16.4377C29.2495 16.2323 28.6333 16.5746 28.4279 17.1224C28.2225 17.6701 28.5648 18.2864 29.1126 18.4918L34.9324 20.4089L41.3002 49.3028H66.7022C67.3185 49.3028 67.7975 48.8235 67.7975 48.2073C67.7975 47.5911 67.3185 47.1118 66.7022 47.1118H43.0805L42.0534 42.4559H69.1669L71.7003 28.5567Z"
      fill="#13c8ec"
    />
    <path
      d="M66.7018 49.6452H41.026L34.5897 20.6829L28.9754 18.8342C28.2222 18.5603 27.8112 17.7387 28.0851 16.9855C28.222 16.6432 28.4275 16.3009 28.7698 16.1639C29.1121 15.9585 29.523 15.9585 29.8654 16.0954L37.0546 18.4234L38.424 24.654L72.1108 28.2829L69.4406 42.7983H42.4636L43.3539 46.7695H66.7018C67.5235 46.7695 68.1394 47.3857 68.1394 48.2073C68.1394 49.029 67.5235 49.6452 66.7018 49.6452ZM41.5736 48.9605H66.7018C67.1126 48.9605 67.4547 48.6181 67.4547 48.2073C67.4547 47.7965 67.1126 47.4542 66.7018 47.4542H42.8059L41.6421 42.1136H68.8926L71.3576 28.8307L37.9449 25.2018L36.5755 18.9027L29.7968 16.6432C29.5914 16.5747 29.3861 16.5747 29.2492 16.7116C29.1123 16.8486 28.9069 16.9855 28.9069 17.1224C28.7699 17.5333 28.9755 17.9441 29.3863 18.081L35.3429 20.0666L41.5736 48.9605Z"
      fill="#22206C"
    />
    <path
      d="M45.8873 58.6833C47.7024 58.6833 49.174 57.2119 49.174 55.3968C49.174 53.5817 47.7024 52.1104 45.8873 52.1104C44.0722 52.1104 42.6009 53.5817 42.6009 55.3968C42.6009 57.2119 44.0722 58.6833 45.8873 58.6833Z"
      fill="#13c8ec"
    />
    <path
      d="M45.8873 59.0253C43.9017 59.0253 42.2586 57.382 42.2586 55.3964C42.2586 53.4108 43.9017 51.7676 45.8873 51.7676C47.8729 51.7676 49.5164 53.4108 49.5164 55.3964C49.5164 57.382 47.8729 59.0253 45.8873 59.0253ZM45.8873 52.4523C44.2441 52.4523 42.9433 53.7531 42.9433 55.3964C42.9433 57.0396 44.2441 58.3406 45.8873 58.3406C47.5306 58.3406 48.8317 57.0396 48.8317 55.3964C48.8317 53.7531 47.4621 52.4523 45.8873 52.4523Z"
      fill="#22206C"
    />
    <path
      d="M62.9364 58.6833C64.7515 58.6833 66.2227 57.2119 66.2227 55.3968C66.2227 53.5817 64.7515 52.1104 62.9364 52.1104C61.1213 52.1104 59.6497 53.5817 59.6497 55.3968C59.6497 57.2119 61.1213 58.6833 62.9364 58.6833Z"
      fill="#13c8ec"
    />
    <path
      d="M62.9364 59.0253C60.9508 59.0253 59.3073 57.382 59.3073 55.3964C59.3073 53.4108 60.9508 51.7676 62.9364 51.7676C64.922 51.7676 66.5651 53.4108 66.5651 55.3964C66.5651 57.382 64.922 59.0253 62.9364 59.0253ZM62.9364 52.4523C61.2931 52.4523 59.992 53.7531 59.992 55.3964C59.992 57.0396 61.2931 58.3406 62.9364 58.3406C64.5796 58.3406 65.8804 57.0396 65.8804 55.3964C65.8804 53.7531 64.5796 52.4523 62.9364 52.4523Z"
      fill="#22206C"
    />
  </svg>
)

export default CartIcon
