/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint max-len: 0 */
import React from 'react'

const ThumbsupIcon = () => (
  <svg
    width="100"
    height="92"
    viewBox="0 0 100 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M92.5514 0H7.36301C3.25342 0 0 3.06667 0 6.7624V63.8496C0 67.6239 3.33903 70.612 7.36301 70.612H33.5616L50 92L66.4383 70.612H92.637C96.7466 70.612 100 67.5453 100 63.8496V6.7624C99.9144 3.06667 96.5753 0 92.5514 0Z"
      fill="#FEFEFE"
    />
    <path
      d="M49.9575 92L33.4749 70.6137H7.73151C3.48342 70.6137 0 67.4252 0 63.5368V7.07693C0 3.18851 3.48342 0 7.73151 0H92.2685C96.5166 0 100 3.18851 100 7.07693V63.5368C100 67.4252 96.5166 70.6137 92.2685 70.6137H66.5251L49.9575 92ZM7.73151 0.699911C3.90823 0.699911 0.849618 3.49958 0.849618 6.99916V63.459C0.849618 66.9586 3.90823 69.7582 7.73151 69.7582H33.8997L49.9575 90.6002L66.0153 69.7582H92.1835C96.0068 69.7582 99.0654 66.9586 99.0654 63.459V6.99916C99.0654 3.49958 96.0068 0.699911 92.1835 0.699911H7.73151Z"
      fill="#261134"
    />
    <path
      d="M49.7331 14.026V22.5488L38.5896 38.5535V51.3702L41.3755 53.4521C42.8703 54.5581 44.7049 55.1437 46.6075 55.1437H65.2932C66.924 55.1437 68.4188 54.2979 69.0983 52.8666C69.5739 51.9557 69.7098 50.7196 69.0304 49.1582C69.0304 49.1582 74.1265 48.7028 72.088 42.9775C72.088 42.9775 76.4367 40.245 72.4957 35.886C72.4957 35.886 75.6213 32.8933 73.379 30.2258C72.5637 29.25 71.2726 28.6644 69.9137 28.6644H57.4112C57.4112 28.7295 61.5561 12.3995 49.7331 14.026Z"
      fill="#FFDFA9"
    />
    <path
      d="M65.2933 55.4695H46.6075C44.637 55.4695 42.7345 54.8188 41.1717 53.6478L38.2499 51.4358V38.3588L38.3178 38.2937L49.3934 22.4192V13.7012L49.6652 13.6361C52.519 13.2458 54.6934 13.8313 56.1882 15.3928C59.5856 18.971 58.2267 26.4529 57.819 28.2745H69.9138C71.4086 28.2745 72.7676 28.8601 73.6509 29.9661C75.6894 32.3733 73.6509 35.0407 72.9714 35.8215C74.1945 37.2528 74.6701 38.619 74.4663 39.9853C74.1945 41.6118 73.0394 42.7178 72.4958 43.0431C73.1073 44.9298 73.0394 46.4912 72.2919 47.5972C71.4766 48.7683 70.1855 49.1587 69.5061 49.3538C69.9817 50.655 69.9817 51.8912 69.4381 52.9321C68.6907 54.4935 67.0599 55.4695 65.2933 55.4695ZM38.9294 51.1755L41.5793 53.1924C43.0063 54.2333 44.7729 54.8189 46.5396 54.8189H65.2253C66.7202 54.8189 68.1471 53.9731 68.7586 52.7369C69.3022 51.696 69.2343 50.5249 68.6907 49.2888L68.4868 48.8984L68.9625 48.8334C68.9625 48.8334 70.7971 48.6382 71.6804 47.337C72.3599 46.2961 72.3599 44.9298 71.7484 43.1081L71.6804 42.8479L71.8843 42.7178C71.8843 42.7178 73.515 41.6768 73.7189 39.9202C73.9227 38.6841 73.3791 37.3829 72.1561 36.0817L71.9522 35.8215L72.1561 35.6263C72.292 35.4962 75.0099 32.8287 72.9714 30.4215C72.224 29.5107 71.0009 28.9902 69.7779 28.9902H56.8677L56.9356 28.5998C56.9356 28.5348 59.11 19.6216 55.5767 15.8482C54.2857 14.4819 52.3831 13.9614 49.937 14.2217V22.5493L49.869 22.6144L38.7935 38.4889V51.1755H38.9294Z"
      fill="#22206C"
    />
    <path
      d="M27.3103 33.2837H38.5897C39.6769 33.2837 40.5602 34.1295 40.5602 35.1704V53.257C40.5602 54.2979 39.6769 55.1437 38.5897 55.1437H27.3103C26.2232 55.1437 25.3398 54.2979 25.3398 53.257V35.1704C25.3398 34.1295 26.2232 33.2837 27.3103 33.2837Z"
      fill="#3329a3"
    />
    <path
      d="M38.5896 55.4691H27.3102C26.0192 55.4691 25 54.4932 25 53.2571V35.1705C25 33.9344 26.0192 32.9585 27.3102 32.9585H38.5896C39.8806 32.9585 40.8998 33.9344 40.8998 35.1705V53.2571C40.8998 54.4281 39.8806 55.4691 38.5896 55.4691ZM27.3102 33.6091C26.4269 33.6091 25.6795 34.3247 25.6795 35.1705V53.2571C25.6795 54.1028 26.4269 54.8185 27.3102 54.8185H38.5896C39.4729 54.8185 40.2203 54.1028 40.2203 53.2571V35.1705C40.2203 34.3247 39.4729 33.6091 38.5896 33.6091H27.3102Z"
      fill="#22206C"
    />
  </svg>
)

export default ThumbsupIcon
