/* Copyright 2013 - 2024 Waiterio LLC */
import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client' // eslint-disable-line import/extensions
import { BrowserRouter } from 'react-router-dom'
import { initMultilocale } from '@multilocale/react/index.js'
import PolyblogProvider from '@polyblog/react/PolyblogProvider.js'
import StageBadge from '@stiloso/components/StageBadge.js'
import ErrorBoundaryGlobalWithSentry from '@stiloso/components/ErrorBoundary.js'
import ScrollToTop from '@stiloso/components/ScrollToTop.js'
import allowSubdomainsCrossOrigin from '@monorepo/shared/allowSubdomainsCrossOrigin.js'
import getSentryBrowser from '@monorepo/telemetry/getSentryBrowser.js'
import GlobalStyles from '@polyblog/shared/GlobalStyles.js'
import Routes from './Routes.js'

allowSubdomainsCrossOrigin()

initMultilocale({
  organizationId: 'e395dba6a14d8af4c2d35890',
  project: 'polyblog-www',
})

createRoot(document.getElementById('root')).render(
  <ErrorBoundaryGlobalWithSentry>
    <BrowserRouter>
      <PolyblogProvider>
        <Suspense>
          <ScrollToTop />
          <GlobalStyles />
          <Routes />
          <StageBadge />
        </Suspense>
      </PolyblogProvider>
    </BrowserRouter>
  </ErrorBoundaryGlobalWithSentry>,
)

getSentryBrowser()
