/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'

const StarIcon = () => (
  <svg
    width="100"
    height="92"
    viewBox="0 0 100 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M92.308 0.40918H7.87179C3.84676 0.40918 0.537109 3.43771 0.537109 7.12098V63.516C0.537109 67.1993 3.84676 70.2277 7.87179 70.2277H33.8109L50.0897 91.3452L66.3689 70.2277H92.308C96.333 70.2277 99.6427 67.1993 99.6427 63.516V7.12098C99.6427 3.35586 96.4225 0.40918 92.308 0.40918Z"
      fill="white"
    />
    <path
      d="M50.0893 92L33.542 70.637H7.78191C3.48854 70.637 0 67.4449 0 63.5161V7.12106C0 3.19223 3.48854 0 7.78191 0H92.2181C96.5115 0 100 3.19223 100 7.12106V63.5161C100 67.4449 96.5115 70.637 92.2181 70.637H66.458L50.0893 92ZM7.87144 0.818505C4.11474 0.818505 0.983985 3.60148 0.983985 7.12106V63.5161C0.983985 66.9538 4.0253 69.8185 7.87144 69.8185H34.0787L50.0893 90.6086L66.1004 69.8185H92.3076C96.0643 69.8185 99.1951 67.0357 99.1951 63.5161V7.12106C99.1951 3.68333 96.1538 0.818505 92.3076 0.818505H7.87144Z"
      fill="#261134"
    />
    <path
      d="M50.8337 15.8062L57.4766 28.5245L72.4764 30.5433L61.7623 40.4353L64.1196 54.5668L50.8337 47.9048L37.5478 54.5668L39.9051 40.4353L29.191 30.5433L44.1907 28.5245L50.8337 15.8062Z"
      fill="#FFDFA9"
    />
    <path
      d="M65.6189 56.3838L50.8337 49.1162L36.0484 56.3838L38.8337 40.8392L26.8337 29.7359L43.3338 27.5153L50.8337 13.3838L58.3335 27.5153L74.8337 29.7359L62.8337 40.8392L65.6189 56.3838ZM50.8337 46.8955L62.8337 52.75L60.4764 40.2335L70.1191 31.3509L56.8332 29.534L50.8337 18.027L44.8342 29.534L31.5483 31.3509L41.191 40.2335L38.8337 52.75L50.8337 46.8955Z"
      fill="#261134"
    />
  </svg>
)

export default StarIcon
