/* Copyright 2013 - 2024 Waiterio LLC */
import getAppUrl from '@polyblog/shared/getAppUrl.js'

export const path = '/signup'

const SignupRedirect = () => {
  const appUrl = getAppUrl()
  window.location.href = `${appUrl}${path}`
  return null
}

export default SignupRedirect
