/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import getCookie from '@monorepo/shared/getCookie.js'
import { jsx, css } from '@emotion/react'
import blogId from '@polyblog/shared/blogId.js'
import domainProduction from '@polyblog/shared/domainProduction.js'
import facebookHandle from '@polyblog/shared/facebookHandle.js'
import faviconUrl from '@polyblog/shared/faviconUrl.js'
import linkedinHandle from '@polyblog/shared/linkedinHandle.js'
import getAppUrl from '@polyblog/shared/getAppUrl.js'
import ErrorBoundary from '@stiloso/components/ErrorBoundary.js'
import IllustrationManWorkingOnComputer from '@stiloso/components/IllustrationManWorkingOnComputer.js'
import BannerSection from '@stiloso/sections/BannerSection.js'
import WhySection from '@stiloso/sections/WhySection.js'
import HowSection from '@stiloso/sections/HowSection.js'
import QuestionsSection from '@stiloso/sections/QuestionsSection.js'
import BlogSection from '@stiloso/sections/BlogSection.js'
import FactsSection from '@stiloso/sections/FactsSection.js'
import { useTranslation } from '@multilocale/react/index.js'
import cartIcon from '@polyblog/www/components/CartIcon.js'
import starIcon from '@polyblog/www/components/StarIcon.js'
import thumbsUpIcon from '@polyblog/www/components/ThumbsupIcon.js'
import DesigningIllustration from '../components/DesigningIllustration.js'
import FooterComponent from '../components/FooterComponent.js'
import LoginIllustration from '../components/LoginIllustration.js'
import NavigationComponent from '../components/NavigationComponent.js'
import WritingIllustration from '../components/WritingIllustration.js'
import languages from '../languages.js'
import product from '../product.js'

export const paths = languages.map(locale => ({
  path: `/${locale}`,
  props: {
    locale,
  },
}))

export const Head = ({ locale = 'en' }) => {
  const { t } = useTranslation(locale)

  const title = t('__brand__', product)
  const bannerTitle = t('__home_banner_title__')
  const bannerSubtitle = t('__home_banner_subtitle__')
  const description = `${bannerTitle} | ${bannerSubtitle}`
  const titleSEO = t('__home_head_title__', `${description} | ${title}`)
  const descriptionSEO = t('__home_head_meta_description__', description)

  return (
    <>
      <title>{titleSEO}</title>
      <meta name="description" content={descriptionSEO} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={faviconUrl} />
      <meta property="og:site_name" content={title} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="article" />
      <meta
        property="og:url"
        content={`https://www.${domainProduction}/${locale}/`}
      />
      <meta property="og:image" content={faviconUrl} />
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={faviconUrl} />
      <link
        rel="canonical"
        href={`https://www.${domainProduction}/${locale}/`}
      />
      <link
        rel="alternate"
        href={`https://www.${domainProduction}/en/`}
        hreflang="x-default"
      />
      {languages.map(language => (
        <link
          key={language}
          rel="alternate"
          href={`https://www.${domainProduction}/${language}/`}
          hreflang={language}
        />
      ))}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'http://schema.org/',
            '@type': 'WebPage',
            '@id': `https://www.${domainProduction}/${locale}/`,
            url: `https://www.${domainProduction}/${locale}/`,
            inLanguage: locale,
            isPartOf: {
              '@id': `https://www.${domainProduction}/${locale}/#website`,
            },
            author: {
              '@id': `https://www.${domainProduction}/#corporation`,
            },
            publisher: {
              '@id': `https://www.${domainProduction}/#corporation`,
            },
            translationOfWork: {
              '@id': `https://www.${domainProduction}/en/#website`,
            },
            '@graph': [
              {
                '@type': 'WebSite',
                '@id': `https://www.${domainProduction}/${locale}/#website`,
                name: 'Polyblog',
                url: `https://www.${domainProduction}/${locale}/#website`,
                inLanguage: locale,
                author: {
                  '@id': `https://www.${domainProduction}/#corporation`,
                },
                publisher: {
                  '@id': `https://www.${domainProduction}/#corporation`,
                },
              },
              {
                '@type': 'Corporation',
                '@id': `https://www.${domainProduction}/#corporation`,
                name: 'Polyblog',
                url: `https://www.${domainProduction}/#corporation`,
                sameAs: [
                  facebookHandle &&
                    `https://www.facebook.com/${facebookHandle}`,
                  linkedinHandle &&
                    `https://www.linkedin.com/company/${linkedinHandle}}`,
                ].filter(_ => _),
              },
            ],
          }),
        }}
      />
    </>
  )
}

const HomePage = ({ locale = 'en' }) => {
  const { t } = useTranslation(locale)
  const appUrl = getAppUrl()

  const documents = [
    {
      title: t('__home_faqs_1_title__'),
      content: `<p>${t('__home_faqs_1_content__')}</p>`,
    },
    {
      title: t('__home_faqs_2_title__'),
      content: `<p>${t('__home_faqs_2_content__')}</p>`,
    },
    {
      title: t('__home_faqs_3_title__'),
      content: `<p>${t('__home_faqs_3_content__')}</p>`,
    },
    {
      title: t('__home_faqs_4_title__'),
      content: `<p>${t('__home_faqs_4_content__')}</p>`,
    },
    {
      title: t('__home_faqs_5_title__'),
      content: `<p>${t('__home_faqs_5_content__')}</p>`,
    },
    {
      title: t('__home_faqs_6_title__'),
      content: `<p>${t(
        '__home_faqs_6_content__',
      )}: <a href="https://www.waiterio.com/blog/${locale}">https://www.waiterio.com/blog</a></p>`,
    },
  ]

  const whySubsections = [
    {
      title: t('__home_why_1_title__'),
      content: t('__home_why_1_content__'),
    },
    {
      title: t('__home_why_2_title__'),
      content: t('__home_why_2_content__'),
    },
    {
      title: t('__home_why_3_title__'),
      content: t('__home_why_3_content__'),
    },
    {
      title: t('__home_why_4_title__'),
      content: t('__home_why_4_content__'),
    },
    {
      title: t('__home_why_5_title__'),
      content: t('__home_why_5_content__'),
    },
  ]

  const facts = [
    {
      content: t('__home_facts_1__'),
      Icon: thumbsUpIcon,
    },
    {
      content: t('__home_facts_2__'),
      Icon: starIcon,
    },
    {
      content: t('__home_facts_3__'),
      Icon: cartIcon,
    },
  ]

  const howSubsections = [
    {
      title: t('__home_how_1_title__'),
      content: t('__home_how_1_content__'),
      Illustration: LoginIllustration,
    },
    {
      title: t('__home_how_2_title__'),
      content: t('__home_how_2_content__'),
      Illustration: WritingIllustration,
    },
    {
      title: t('__home_how_3_title__'),
      content: t('__home_how_3_content__'),
      Illustration: DesigningIllustration,
    },
  ]

  let showDashboard = !!getCookie(product + 'AccessToken')

  return (
    <>
      <NavigationComponent locale={locale} />
      <ErrorBoundary>
        <BannerSection
          title={t('__home_banner_title__')}
          subtitle={t('__home_banner_subtitle__')}
          buttonLabel={t(showDashboard ? 'Go to the App' : 'Get started')}
          buttonOnClick={() => {
            window.location.href = showDashboard ? appUrl : `${appUrl}/signup`
          }}
        />
        <WhySection
          title={t('__home_why_title__')}
          subsections={whySubsections}
          illustration={<IllustrationManWorkingOnComputer />}
        />
        <FactsSection title={t('__home_facts_title__')} facts={facts} />
        <HowSection
          title={t('__home_how_title__')}
          subsections={howSubsections}
        />
        <QuestionsSection
          locale={locale}
          title={t('__home_questions_title__')}
          documents={documents}
        />
        <BlogSection blogId={blogId} locale={locale} />
      </ErrorBoundary>
      <FooterComponent locale={locale} />
    </>
  )
}

export default HomePage
